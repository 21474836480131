// 
// Generated by @himenon/openapi-typescript-code-generator v0.17.0
// 
// OpenApi : 3.0.0
// 
// 


import { Schemas } from "./types";
export interface Parameter$mediaGetFile {
    filename: string;
}
export interface Response$mediaGetFile$Status$200 {
    "application/json": any;
}
export interface RequestBody$adminUploadUpload {
    "multipart/form-data": Schemas.MediaFileUploadDto;
}
export interface Response$adminUploadUpload$Status$200 {
    "application/json": Schemas.FileEntities[];
}
export interface Parameter$adminUploadPutFile {
    fileId: string;
}
export interface RequestBody$adminUploadPutFile {
    "application/json": Schemas.MediaUpdateDto;
}
export interface Response$adminUploadPutFile$Status$200 {
    "application/json": Schemas.FileEntities;
}
export interface RequestBody$adminCreateAdmin {
    "application/json": Schemas.CreateAdminDto;
}
export interface Response$adminCreateAdmin$Status$201 {
    "application/json": Schemas.CreateAdminResponseDto;
}
export interface Response$adminAdminMe$Status$200 {
    "application/json": Schemas.AdminEntities;
}
export interface RequestBody$adminSignin {
    "application/json": Schemas.AdminLoginDto;
}
export interface Response$adminSignin$Status$200 {
    "application/json": Schemas.AdminLoginResponseDto;
}
export interface RequestBody$adminPasswordChange {
    "application/json": Schemas.AdminPasswordChangeRequestDto;
}
export interface Parameter$adminNewsGetAllNews {
    limit?: number;
    page?: number;
    tag?: string;
    title?: string;
}
export interface Response$adminNewsGetAllNews$Status$200 {
    "application/json": Schemas.AdminNewsListResponseDto;
}
export interface RequestBody$adminNewsInsertNews {
    "application/json": Schemas.AdminUpdateNewsDto;
}
export interface Response$adminNewsInsertNews$Status$201 {
    "application/json": Schemas.NewsEntities;
}
export interface Parameter$adminNewsGetOneNews {
    /** news uuid */
    uuid: string;
}
export interface Response$adminNewsGetOneNews$Status$200 {
    "application/json": Schemas.NewsEntities;
}
export interface Parameter$adminNewsUpdateNews {
    /** news uuid */
    uuid: string;
}
export interface RequestBody$adminNewsUpdateNews {
    "application/json": Schemas.AdminUpdateNewsDto;
}
export interface Response$adminNewsUpdateNews$Status$200 {
    "application/json": Schemas.NewsEntities;
}
export interface Parameter$adminNewsDeleteNews {
    /** news uuid */
    uuid: string;
}
export interface Parameter$adminInquiryGetOneInquiry {
    /** inquiry uuid */
    uuid: string;
}
export interface Response$adminInquiryGetOneInquiry$Status$200 {
    "application/json": Schemas.InquiryEntities;
}
export interface Parameter$adminInquiryGetAllInquiries {
    limit?: number;
    page?: number;
    inquiryType?: number;
}
export interface Response$adminInquiryGetAllInquiries$Status$200 {
    "application/json": Schemas.InquiryResponseDto;
}
export interface RequestBody$clientInquiryInsertInquiry {
    "application/json": Schemas.ClientInquiryDto;
}
export interface Response$clientInquiryInsertInquiry$Status$201 {
    "application/json": Schemas.ClientInquiryDto;
}
export interface Parameter$clientNewsGetOneNews {
    /** news uuid */
    uuid: string;
}
export interface Response$clientNewsGetOneNews$Status$200 {
    "application/json": Schemas.NewsEntities;
}
export interface Parameter$clientNewsGetAllNews {
    limit?: number;
    page?: number;
    tag?: string;
    title?: string;
}
export interface Response$clientNewsGetAllNews$Status$200 {
    "application/json": Schemas.ClientNewsListResponseDto;
}
export type ResponseContentType$mediaGetFile = keyof Response$mediaGetFile$Status$200;
export interface Params$mediaGetFile {
    parameter: Parameter$mediaGetFile;
}
export type RequestContentType$adminUploadUpload = keyof RequestBody$adminUploadUpload;
export type ResponseContentType$adminUploadUpload = keyof Response$adminUploadUpload$Status$200;
export interface Params$adminUploadUpload {
    requestBody: RequestBody$adminUploadUpload["multipart/form-data"];
}
export type RequestContentType$adminUploadPutFile = keyof RequestBody$adminUploadPutFile;
export type ResponseContentType$adminUploadPutFile = keyof Response$adminUploadPutFile$Status$200;
export interface Params$adminUploadPutFile {
    parameter: Parameter$adminUploadPutFile;
    requestBody: RequestBody$adminUploadPutFile["application/json"];
}
export type RequestContentType$adminCreateAdmin = keyof RequestBody$adminCreateAdmin;
export type ResponseContentType$adminCreateAdmin = keyof Response$adminCreateAdmin$Status$201;
export interface Params$adminCreateAdmin {
    requestBody: RequestBody$adminCreateAdmin["application/json"];
}
export type ResponseContentType$adminAdminMe = keyof Response$adminAdminMe$Status$200;
export type RequestContentType$adminSignin = keyof RequestBody$adminSignin;
export type ResponseContentType$adminSignin = keyof Response$adminSignin$Status$200;
export interface Params$adminSignin {
    requestBody: RequestBody$adminSignin["application/json"];
}
export type RequestContentType$adminPasswordChange = keyof RequestBody$adminPasswordChange;
export interface Params$adminPasswordChange {
    requestBody: RequestBody$adminPasswordChange["application/json"];
}
export type ResponseContentType$adminNewsGetAllNews = keyof Response$adminNewsGetAllNews$Status$200;
export interface Params$adminNewsGetAllNews {
    parameter: Parameter$adminNewsGetAllNews;
}
export type RequestContentType$adminNewsInsertNews = keyof RequestBody$adminNewsInsertNews;
export type ResponseContentType$adminNewsInsertNews = keyof Response$adminNewsInsertNews$Status$201;
export interface Params$adminNewsInsertNews {
    requestBody: RequestBody$adminNewsInsertNews["application/json"];
}
export type ResponseContentType$adminNewsGetOneNews = keyof Response$adminNewsGetOneNews$Status$200;
export interface Params$adminNewsGetOneNews {
    parameter: Parameter$adminNewsGetOneNews;
}
export type RequestContentType$adminNewsUpdateNews = keyof RequestBody$adminNewsUpdateNews;
export type ResponseContentType$adminNewsUpdateNews = keyof Response$adminNewsUpdateNews$Status$200;
export interface Params$adminNewsUpdateNews {
    parameter: Parameter$adminNewsUpdateNews;
    requestBody: RequestBody$adminNewsUpdateNews["application/json"];
}
export interface Params$adminNewsDeleteNews {
    parameter: Parameter$adminNewsDeleteNews;
}
export type ResponseContentType$adminInquiryGetOneInquiry = keyof Response$adminInquiryGetOneInquiry$Status$200;
export interface Params$adminInquiryGetOneInquiry {
    parameter: Parameter$adminInquiryGetOneInquiry;
}
export type ResponseContentType$adminInquiryGetAllInquiries = keyof Response$adminInquiryGetAllInquiries$Status$200;
export interface Params$adminInquiryGetAllInquiries {
    parameter: Parameter$adminInquiryGetAllInquiries;
}
export type RequestContentType$clientInquiryInsertInquiry = keyof RequestBody$clientInquiryInsertInquiry;
export type ResponseContentType$clientInquiryInsertInquiry = keyof Response$clientInquiryInsertInquiry$Status$201;
export interface Params$clientInquiryInsertInquiry {
    requestBody: RequestBody$clientInquiryInsertInquiry["application/json"];
}
export type ResponseContentType$clientNewsGetOneNews = keyof Response$clientNewsGetOneNews$Status$200;
export interface Params$clientNewsGetOneNews {
    parameter: Parameter$clientNewsGetOneNews;
}
export type ResponseContentType$clientNewsGetAllNews = keyof Response$clientNewsGetAllNews$Status$200;
export interface Params$clientNewsGetAllNews {
    parameter: Parameter$clientNewsGetAllNews;
}
export type HttpMethod = "GET" | "PUT" | "POST" | "DELETE" | "OPTIONS" | "HEAD" | "PATCH" | "TRACE";
export interface ObjectLike {
    [key: string]: any;
}
export interface QueryParameter {
    value: any;
    style?: "form" | "spaceDelimited" | "pipeDelimited" | "deepObject";
    explode: boolean;
}
export interface QueryParameters {
    [key: string]: QueryParameter;
}
export type SuccessResponses = Response$mediaGetFile$Status$200 | Response$adminUploadUpload$Status$200 | Response$adminUploadPutFile$Status$200 | Response$adminCreateAdmin$Status$201 | Response$adminAdminMe$Status$200 | Response$adminSignin$Status$200 | Response$adminNewsGetAllNews$Status$200 | Response$adminNewsInsertNews$Status$201 | Response$adminNewsGetOneNews$Status$200 | Response$adminNewsUpdateNews$Status$200 | Response$adminInquiryGetOneInquiry$Status$200 | Response$adminInquiryGetAllInquiries$Status$200 | Response$clientInquiryInsertInquiry$Status$201 | Response$clientNewsGetOneNews$Status$200 | Response$clientNewsGetAllNews$Status$200;
export namespace ErrorResponse {
    export type appGetHC = void;
    export type mediaGetFile = void;
    export type adminUploadUpload = void;
    export type adminUploadPutFile = void;
    export type adminCreateAdmin = void;
    export type adminAdminMe = void;
    export type adminSignin = void;
    export type adminPasswordChange = void;
    export type adminSignout = void;
    export type adminNewsGetAllNews = void;
    export type adminNewsInsertNews = void;
    export type adminNewsGetOneNews = void;
    export type adminNewsUpdateNews = void;
    export type adminNewsDeleteNews = void;
    export type adminInquiryGetOneInquiry = void;
    export type adminInquiryGetAllInquiries = void;
    export type clientInquiryInsertInquiry = void;
    export type clientNewsGetOneNews = void;
    export type clientNewsGetAllNews = void;
}
export interface ApiClient<RequestOption> {
    request: <T = SuccessResponses>(httpMethod: HttpMethod, url: string, headers: ObjectLike | any, requestBody: ObjectLike | any, queryParameters: QueryParameters | undefined, options?: RequestOption) => Promise<T>;
}
export class Client<RequestOption> {
    private baseUrl: string;
    constructor(private apiClient: ApiClient<RequestOption>, baseUrl: string) { this.baseUrl = baseUrl.replace(/\/$/, ""); }
    public async appGetHC(option?: RequestOption): Promise<void> {
        const url = this.baseUrl + `/api/hc`;
        const headers = {};
        return this.apiClient.request("GET", url, headers, undefined, undefined, option);
    }
    /** ファイル取得 */
    public async mediaGetFile(params: Params$mediaGetFile, option?: RequestOption): Promise<Response$mediaGetFile$Status$200["application/json"]> {
        const url = this.baseUrl + `/api/media/*/*/${params.parameter.filename}`;
        const headers = {
            Accept: "application/json"
        };
        return this.apiClient.request("GET", url, headers, undefined, undefined, option);
    }
    /** ファイルアップロード */
    public async adminUploadUpload(params: Params$adminUploadUpload, option?: RequestOption): Promise<Response$adminUploadUpload$Status$200["application/json"]> {
        const url = this.baseUrl + `/api/admin/upload`;
        const headers = {
            "Content-Type": "multipart/form-data",
            Accept: "application/json"
        };
        return this.apiClient.request("POST", url, headers, params.requestBody, undefined, option);
    }
    /** 任意のファイル情報を修正 */
    public async adminUploadPutFile(params: Params$adminUploadPutFile, option?: RequestOption): Promise<Response$adminUploadPutFile$Status$200["application/json"]> {
        const url = this.baseUrl + `/api/admin/upload/${params.parameter.fileId}`;
        const headers = {
            "Content-Type": "application/json",
            Accept: "application/json"
        };
        return this.apiClient.request("PUT", url, headers, params.requestBody, undefined, option);
    }
    /** ユーザーを新規追加 */
    public async adminCreateAdmin(params: Params$adminCreateAdmin, option?: RequestOption): Promise<Response$adminCreateAdmin$Status$201["application/json"]> {
        const url = this.baseUrl + `/api/admin/create`;
        const headers = {
            "Content-Type": "application/json",
            Accept: "application/json"
        };
        return this.apiClient.request("POST", url, headers, params.requestBody, undefined, option);
    }
    /** admin get me */
    public async adminAdminMe(option?: RequestOption): Promise<Response$adminAdminMe$Status$200["application/json"]> {
        const url = this.baseUrl + `/api/admin/me`;
        const headers = {
            Accept: "application/json"
        };
        return this.apiClient.request("GET", url, headers, undefined, undefined, option);
    }
    /** sign in */
    public async adminSignin(params: Params$adminSignin, option?: RequestOption): Promise<Response$adminSignin$Status$200["application/json"]> {
        const url = this.baseUrl + `/api/admin/signin`;
        const headers = {
            "Content-Type": "application/json",
            Accept: "application/json"
        };
        return this.apiClient.request("POST", url, headers, params.requestBody, undefined, option);
    }
    /** パスワード変更 */
    public async adminPasswordChange(params: Params$adminPasswordChange, option?: RequestOption): Promise<void> {
        const url = this.baseUrl + `/api/admin/changepassword`;
        const headers = {
            "Content-Type": "application/json"
        };
        return this.apiClient.request("PUT", url, headers, params.requestBody, undefined, option);
    }
    /** sign out */
    public async adminSignout(option?: RequestOption): Promise<void> {
        const url = this.baseUrl + `/api/admin/signout`;
        const headers = {};
        return this.apiClient.request("POST", url, headers, undefined, undefined, option);
    }
    /** ニュース一覧 */
    public async adminNewsGetAllNews(params: Params$adminNewsGetAllNews, option?: RequestOption): Promise<Response$adminNewsGetAllNews$Status$200["application/json"]> {
        const url = this.baseUrl + `/api/admin/news`;
        const headers = {
            Accept: "application/json"
        };
        const queryParameters: QueryParameters = {
            limit: { value: params.parameter.limit, explode: false },
            page: { value: params.parameter.page, explode: false },
            tag: { value: params.parameter.tag, explode: false },
            title: { value: params.parameter.title, explode: false }
        };
        return this.apiClient.request("GET", url, headers, undefined, queryParameters, option);
    }
    /** ニュース作成 */
    public async adminNewsInsertNews(params: Params$adminNewsInsertNews, option?: RequestOption): Promise<Response$adminNewsInsertNews$Status$201["application/json"]> {
        const url = this.baseUrl + `/api/admin/news`;
        const headers = {
            "Content-Type": "application/json",
            Accept: "application/json"
        };
        return this.apiClient.request("POST", url, headers, params.requestBody, undefined, option);
    }
    /** ニュース詳細 */
    public async adminNewsGetOneNews(params: Params$adminNewsGetOneNews, option?: RequestOption): Promise<Response$adminNewsGetOneNews$Status$200["application/json"]> {
        const url = this.baseUrl + `/api/admin/news/${params.parameter.uuid}`;
        const headers = {
            Accept: "application/json"
        };
        return this.apiClient.request("GET", url, headers, undefined, undefined, option);
    }
    /** ニュース編集 */
    public async adminNewsUpdateNews(params: Params$adminNewsUpdateNews, option?: RequestOption): Promise<Response$adminNewsUpdateNews$Status$200["application/json"]> {
        const url = this.baseUrl + `/api/admin/news/${params.parameter.uuid}`;
        const headers = {
            "Content-Type": "application/json",
            Accept: "application/json"
        };
        return this.apiClient.request("PUT", url, headers, params.requestBody, undefined, option);
    }
    /** ニュース削除 */
    public async adminNewsDeleteNews(params: Params$adminNewsDeleteNews, option?: RequestOption): Promise<void> {
        const url = this.baseUrl + `/api/admin/news/${params.parameter.uuid}`;
        const headers = {};
        return this.apiClient.request("DELETE", url, headers, undefined, undefined, option);
    }
    /** Get inquiry by uuid */
    public async adminInquiryGetOneInquiry(params: Params$adminInquiryGetOneInquiry, option?: RequestOption): Promise<Response$adminInquiryGetOneInquiry$Status$200["application/json"]> {
        const url = this.baseUrl + `/api/admin/inquiry/${params.parameter.uuid}`;
        const headers = {
            Accept: "application/json"
        };
        return this.apiClient.request("GET", url, headers, undefined, undefined, option);
    }
    /** Get all Inquiries */
    public async adminInquiryGetAllInquiries(params: Params$adminInquiryGetAllInquiries, option?: RequestOption): Promise<Response$adminInquiryGetAllInquiries$Status$200["application/json"]> {
        const url = this.baseUrl + `/api/admin/inquiry`;
        const headers = {
            Accept: "application/json"
        };
        const queryParameters: QueryParameters = {
            limit: { value: params.parameter.limit, explode: false },
            page: { value: params.parameter.page, explode: false },
            inquiryType: { value: params.parameter.inquiryType, explode: false }
        };
        return this.apiClient.request("GET", url, headers, undefined, queryParameters, option);
    }
    /** 問い合わせ新規追加 */
    public async clientInquiryInsertInquiry(params: Params$clientInquiryInsertInquiry, option?: RequestOption): Promise<Response$clientInquiryInsertInquiry$Status$201["application/json"]> {
        const url = this.baseUrl + `/api/client/inquiry`;
        const headers = {
            "Content-Type": "application/json",
            Accept: "application/json"
        };
        return this.apiClient.request("POST", url, headers, params.requestBody, undefined, option);
    }
    /** ニュース詳細 */
    public async clientNewsGetOneNews(params: Params$clientNewsGetOneNews, option?: RequestOption): Promise<Response$clientNewsGetOneNews$Status$200["application/json"]> {
        const url = this.baseUrl + `/api/client/news/${params.parameter.uuid}`;
        const headers = {
            Accept: "application/json"
        };
        return this.apiClient.request("GET", url, headers, undefined, undefined, option);
    }
    /** ニュース一覧 */
    public async clientNewsGetAllNews(params: Params$clientNewsGetAllNews, option?: RequestOption): Promise<Response$clientNewsGetAllNews$Status$200["application/json"]> {
        const url = this.baseUrl + `/api/client/news`;
        const headers = {
            Accept: "application/json"
        };
        const queryParameters: QueryParameters = {
            limit: { value: params.parameter.limit, explode: false },
            page: { value: params.parameter.page, explode: false },
            tag: { value: params.parameter.tag, explode: false },
            title: { value: params.parameter.title, explode: false }
        };
        return this.apiClient.request("GET", url, headers, undefined, queryParameters, option);
    }
}
