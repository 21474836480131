import axios, { AxiosRequestConfig, Method } from 'axios'
import mapValues from 'lodash/mapValues'

import { Client, HttpMethod, ObjectLike, QueryParameters, SuccessResponses } from '~/apis/apiClient'
import { IAxiosError } from '~/util/model/error-response'

export const useApiClient = () => {
    return createApiClient()
}

export const useHookApi = () => {
    const errorText = useErrorText()

    return async <T>(api: Promise<T>): Promise<T> => {
        try {
            return await api
        } catch (_reason) {
            const reason = _reason as IAxiosError
            reason.message = errorText(reason)
            throw reason
        }
    }
}

export const useErrorText = () => {
    return (reason: IAxiosError): string => {
        const code = reason.response?.data?.errorCode
        if (!code && code !== 0) {
            // errorCodeが入っていない場合
            if (typeof reason.response?.data?.message === 'object')
                return `${reason.response?.data?.message.reduce((prev, cur) => `${prev} ${cur}`, '')}`
            if (reason.response?.data.message) return (reason.response.data.message as string[])!.toString()
            return 'ネットワークエラーが発生しました。時間を空けて再度実行してください'
        }

        return `(${reason.response?.data?.errorCode}) ` + reason.response?.data?.message?.[0]
    }
}

export const createApiClient = <T = SuccessResponses>(
    baseUrl = process.env.BASE_URL || process.env.NEXT_PUBLIC_BASE_URL || '',
): Client<T> => {
    return new Client(
        {
            async request<T>(
                httpMethod: HttpMethod,
                url: string,
                headers: ObjectLike,
                requestBody: ObjectLike,
                queryParameters: QueryParameters | undefined,
                options?: AxiosRequestConfig,
            ): Promise<T> {
                return (
                    await axios.request({
                        method: httpMethod as Method,
                        url,
                        headers,
                        data: requestBody,
                        params: queryParameters ? mapValues(queryParameters, (it) => it.value) : undefined,
                        ...options,
                    })
                ).data as T
            },
        },
        baseUrl,
    )
}
