import { Badge, Box, Divider, Flex, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import type { VFC } from 'react'

import { Schemas } from '~/apis/types'

type NewsEntities = Schemas.NewsEntities

export type CNewsReleaseViewProps = {} & CNewsReleaseProps

export const CNewsReleaseView: VFC<CNewsReleaseViewProps> = ({ newsItem }) => {
    newsItem.publishedOn = dayjs(newsItem.publishedOn).format('YYYY/MM/DD')
    return (
        <>
            <Box data-testid="c-news-release" pr={4}>
                <Text
                    fontFamily="DIN Alternate"
                    fontWeight="bold"
                    fontSize="md"
                    lineHeight="130%"
                    letterSpacing="0.08em"
                    color="text.gray">
                    {newsItem.publishedOn}
                </Text>
                <Box display="flex" flexWrap="wrap" rowGap="5px" mt="4" fontFamily="Noto Sans JP" letterSpacing="0.08em">
                    {newsItem.tags.map((tag) => (
                        <Badge mr="5px" key={tag.tagName} fontWeight="400" border="1px">
                            {tag.tagName}
                        </Badge>
                    ))}
                </Box>
                <Flex align={{ base: 'normal', md: 'center' }} mt={4}>
                    <Box
                        as="a"
                        textDecorationLine="underline"
                        href={`/news/${newsItem.uuid}`}
                        fontFamily="Noto Sans JP"
                        fontSize="md"
                        fontWeight="normal"
                        color="text.link"
                        lineHeight="130%"
                        letterSpacing="0.08em"
                        overflowWrap="anywhere">
                        {newsItem.title}
                    </Box>
                </Flex>
                <Divider mt="16px" opacity="1" w={'100%'} />
            </Box>
        </>
    )
}

export type CNewsReleaseProps = {
    newsItem: NewsEntities
}

export const CNewsRelease: VFC<CNewsReleaseProps> = (props) => {
    return <CNewsReleaseView {...props} />
}
